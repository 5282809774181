const MutationProfilesAssignJob = `
  mutation ($id: Int!, $jobId: Int!) {
    profilesAssignJob(
      input: {
        id: $id,
        jobId: $jobId,
      }
    ) {
      applicant {
        id
      }
    }
  }
`

export default MutationProfilesAssignJob
