import { useRouter } from 'next/router'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import configuration from '~/configuration'
import { IAttachmentsFile } from '~/core/@types/global'
import useMutationGraphQL from '~/core/middleware/use-mutation-graphQL'
import { openAlert } from '~/core/ui/AlertDialog'
import { Drawer } from '~/core/ui/Drawer'
import { UploadFileDragAndDrop } from '~/core/ui/FileDragAndDrop'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import MutationProfilesAssignJob from '~/lib/features/candidates/graphql/mutation-assign-profile-to-job'
import MutationDeleteProfile from '~/lib/features/candidates/graphql/mutation-delete-profile'
import useCandidateStore from '~/lib/features/candidates/store'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import {
  LIST_ACCEPT_FILES_PDF_DOC,
  MAXIMUM_10_MB
} from '~/lib/hooks/use-upload-s3-aws'
import useBoundStore from '~/lib/store'
import UploadCVFileUpload from './UploadCVFileUpload'
import UploadCVProfileView from './UploadCVProfileView'

const UploadCVContainer: FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { setToast, setShowLockApp, setCloseLockApp, setRefetchMyList } =
    useBoundStore()
  const {
    openUploadDrawer: openDrawer,
    setOpenUploadDrawer: setOpenDrawer,
    uploadDrawerJobId,
    setUploadDrawerJobId,
    isShowFullDrawer,
    setShowFullDrawer
  } = useCandidateStore()

  const { trigger: triggerDeleteProfile, isLoading: isLoadingProfile } =
    useSubmitCommon(MutationDeleteProfile)
  const { trigger: triggerApplicantsChangeStage, isLoading } =
    useMutationGraphQL({ query: MutationProfilesAssignJob })

  const [files, setFiles] = useState<Array<IAttachmentsFile>>([])
  const filesRef = useRef<Array<IAttachmentsFile>>([])
  const [fileIndex, setFileIndex] = useState(0)
  const calcHeight = isShowFullDrawer
    ? 'h-[calc(100vh_-_122px)]'
    : 'h-[calc(100vh_-_57px)]'

  // ----- API ----- //

  const deleteProfileNoteAttachmentCallback = useCallback(
    async (params: { ids: Array<number> }) => {
      if (isLoadingProfile) {
        return Promise.resolve(false)
      }

      return triggerDeleteProfile({
        ids: params.ids
      }).then((result) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
          return Promise.resolve(false)
        }

        const { profilesDelete } = result.data
        if (profilesDelete.success) {
          return Promise.resolve(true)
        }

        return Promise.resolve(true)
      })
    },
    [isLoadingProfile, triggerDeleteProfile, setToast]
  )

  const assignJobCallback = useCallback(
    async (data: { profileId: number; jobId?: number }) => {
      if (!data.jobId) Promise.resolve(false)

      if (isLoading) {
        return Promise.resolve(true)
      }

      triggerApplicantsChangeStage({
        id: data.profileId,
        jobId: data?.jobId
      }).then((result) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.candidates.list,
            setToast
          })

          return Promise.resolve(true)
        }

        const { profilesAssignJob } = result.data
        if (profilesAssignJob.applicant.id) {
          setToast({
            open: true,
            type: 'success',
            title: t('notification:upload:uploadSuccessful')
          })
          setOpenDrawer(false)
          setUploadDrawerJobId([])
          setShowFullDrawer(false)
          setRefetchMyList(true)
        }

        return Promise.resolve(true)
      })

      return Promise.resolve(true)
    },
    [
      isLoading,
      triggerApplicantsChangeStage,
      setToast,
      t,
      setOpenDrawer,
      setUploadDrawerJobId,
      setShowFullDrawer,
      setRefetchMyList
    ]
  )
  // ----- RENDERED ----- //

  const handleOpenDiscardUnsavedChanges = () => {
    if (filesRef?.current?.length) {
      openAlert({
        isPreventAutoFocusDialog: false,
        className: 'w-[480px]',
        title: `${t('common:modal:discard_unsaved_changes_title')}`,
        description: t('common:modal:discard_unsaved_changes_description'),
        actions: [
          {
            label: `${t('button:keepEditing')}`,
            type: 'secondary',
            size: 'sm'
          },
          {
            label: `${t('button:discard')}`,
            type: 'destructive',
            size: 'sm',
            onClick: async () => {
              const filter = (filesRef?.current || []).filter(
                (item) => item.profileId
              )
              if (filter.length) {
                setShowLockApp('')

                const mappingIds = filter.map((item) => Number(item.profileId))
                deleteProfileNoteAttachmentCallback({
                  ids: mappingIds
                }).then((result) => {
                  if (result) {
                    setToast({
                      open: true,
                      type: 'success',
                      title: t('notification:upload:uploadCanceled')
                    })

                    setCloseLockApp()
                    void router.reload()
                  }
                })
              } else {
                void router.reload()
              }
            }
          }
        ]
      })
    } else {
      handleCancel()
    }
  }

  const handleCancel = () => {
    setFiles([])
    if (filesRef?.current) {
      filesRef.current = []
    }
    setOpenDrawer(false)
    setUploadDrawerJobId([])
    setShowFullDrawer(false)
  }
  const handleOK = () => {
    if (uploadDrawerJobId?.length) {
      const findDefaultValue = uploadDrawerJobId?.find(
        (item) => !!item.defaultValue
      )

      if (findDefaultValue?.jobId) {
        uploadDrawerJobId.forEach((fo) => {
          if (fo.profileId) {
            assignJobCallback({
              profileId: Number(fo.profileId),
              jobId: Number(findDefaultValue.jobId)
            })
          }
        })
      } else {
        uploadDrawerJobId.forEach((fo) => {
          if (fo.profileId) {
            assignJobCallback({
              profileId: Number(fo.profileId),
              jobId: Number(fo.jobId)
            })
          }
        })
      }
    } else {
      setToast({
        open: true,
        type: 'success',
        title: t('notification:upload:uploadSuccessful')
      })
      setOpenDrawer(false)
      setUploadDrawerJobId([])
      setShowFullDrawer(false)
      setRefetchMyList(true)
    }
  }

  const handleSetFileIndex = (fileINDEX: number) => {
    setFileIndex(fileINDEX)
    if (isShowFullDrawer === false) {
      setShowFullDrawer(true)
    }
  }

  const renderedContent = () => (
    <div className={cn('flex', calcHeight)}>
      <ScrollArea className={calcHeight}>
        <div className={cn('w-[280px] space-y-3')}>
          <div className="mt-4 px-6">
            <UploadFileDragAndDrop
              isDisabled={(filesRef?.current || []).length >= 50}
              className="w-full"
              classNameWrapper="px-0 py-4"
              configText={{
                clickToUpload: `${t('label:dragAndDrop:clickToUpload')}`,
                orDragAndDrop: `${t('label:dragAndDrop:orDragAndDrop')}`,
                delete: `${t('tooltip:delete')}`,
                tryAgain: `${t('tooltip:tryAgain')}`,
                uploadANewFile: `${t('tooltip:uploadANewFile')}`
              }}
              dragNDropHelperText={`${t('careers:applied:dragNDropTypeFiles')}`}
              maximumFiles={50}
              maximumSizeFile="10MB"
              accept={{
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  ['.docx']
              }}
              showRejectFiles={false}
              onChange={(fileList) => {
                if (
                  (fileList || []).length + (filesRef?.current || []).length <=
                  50
                ) {
                  let arr: Array<IAttachmentsFile> = []
                  Array.from(fileList || []).forEach((file) => {
                    const checkFileIsDuplicate = (
                      filesRef?.current || []
                    ).filter((item) => {
                      return (
                        item?.file?.name === file.name &&
                        item?.file?.size === file.size
                      )
                    })

                    arr.push({
                      id: undefined,
                      name: file.name,
                      status:
                        file.size > MAXIMUM_10_MB ||
                        !LIST_ACCEPT_FILES_PDF_DOC.includes(file.type) ||
                        checkFileIsDuplicate.length
                          ? 'error'
                          : 'pending',
                      statusDescription:
                        file.size > MAXIMUM_10_MB
                          ? `${t('form:maximum_size_is_10MB')}`
                          : !LIST_ACCEPT_FILES_PDF_DOC.includes(file.type)
                          ? `${t('form:only_supported_PDF_DOCX_DOC')}`
                          : checkFileIsDuplicate.length
                          ? `${t('form:duplicatedFile')}`
                          : '',
                      file: file
                    })
                  })
                  setFiles([...(filesRef?.current || []), ...arr])
                  if (filesRef?.current) {
                    filesRef.current = [...filesRef.current, ...arr]
                  }
                  setShowFullDrawer(true)
                } else {
                  setToast({
                    open: true,
                    type: 'error',
                    title: t('notification:upload:uploadFailed'),
                    description: `${t(
                      'notification:upload:uploadFailedDescription',
                      { number: 50 }
                    )}`
                  })
                }
              }}
            />
          </div>
          {files.length ? (
            <>
              <TypographyText className="px-6 text-sm text-gray-600">
                {t('candidates:fileUploadCompleted', {
                  fileUpload: files.filter((item) => item.id)?.length,
                  files: files.length
                })}
              </TypographyText>
              <div className="space-y-1">
                {files.map((item, index) => (
                  <div key={`file-${index}`}>
                    <UploadCVFileUpload
                      uploadDrawerJobId={uploadDrawerJobId}
                      item={item}
                      index={index}
                      fileIndex={fileIndex}
                      setFileIndex={(fileIndex) =>
                        handleSetFileIndex(fileIndex)
                      }
                      onUpdateProfileId={({ item }) => {
                        const cloneFiles = JSON.parse(
                          JSON.stringify(filesRef?.current)
                        )
                        cloneFiles[index] = item
                        setFiles(cloneFiles)
                        if (filesRef?.current) {
                          filesRef.current = cloneFiles
                        }

                        let results = []
                        const findDefaultValue = uploadDrawerJobId?.find(
                          (item) => !!item.defaultValue
                        )

                        results.push(findDefaultValue)
                        cloneFiles?.forEach(
                          (item: {
                            defaultValue?: boolean
                            profileId?: string
                            jobId?: string
                            jobTitle?: string
                          }) => {
                            const profileId = item.profileId
                            const findValue = uploadDrawerJobId?.find(
                              (s) => String(s.profileId) === String(profileId)
                            )

                            if (findValue) {
                              results.push(findValue)
                            } else {
                              results.push({
                                profileId: item.profileId,
                                jobId: findDefaultValue?.jobId,
                                jobTitle: findDefaultValue?.jobTitle
                              })
                            }
                          }
                        )

                        setUploadDrawerJobId(results as [])
                      }}
                      onDeletePendingFile={() => {
                        const cloneFiles = JSON.parse(
                          JSON.stringify(filesRef?.current)
                        )
                        cloneFiles.splice(index, 1)
                        setFiles(cloneFiles)
                        if (filesRef?.current) {
                          filesRef.current = cloneFiles
                        }
                        if (fileIndex === index) {
                          handleSetFileIndex(0)
                        }
                      }}
                      onDeleteProfile={() =>
                        deleteProfileNoteAttachmentCallback({
                          ids: [Number(item.profileId)]
                        }).then((result) => {
                          if (fileIndex === index) {
                            handleSetFileIndex(0)
                          }
                          if (result) {
                            setToast({
                              open: true,
                              type: 'success',
                              title: t('notification:upload:profileDeleted')
                            })

                            const cloneFiles = JSON.parse(
                              JSON.stringify(filesRef?.current)
                            )
                            cloneFiles.splice(index, 1)
                            setFiles(cloneFiles)
                            if (filesRef?.current) {
                              filesRef.current = cloneFiles
                            }
                          }
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <TypographyText className="px-6 text-xs text-gray-500">
              {t('candidates:descriptionFileUploadCompleted')}
            </TypographyText>
          )}
        </div>
      </ScrollArea>
      {isShowFullDrawer ? (
        <UploadCVProfileView item={files[fileIndex]} />
      ) : null}
    </div>
  )

  const drawerContainerRef = useRef<HTMLDivElement>(null)
  return (
    <UploadCVContext.Provider value={{ drawerContainerRef }}>
      <Drawer
        position="left"
        size="full"
        drawerClassName={cn(
          'max-w-[282px]',
          isShowFullDrawer ? '!max-w-[calc(100vw_-_60px)]' : ''
        )}
        configHeader={{
          title: `${t('button:uploadCvsResumes')}`
        }}
        configFooter={{
          actions: isShowFullDrawer
            ? [
                {
                  configurations: 'ghost',
                  type: 'secondary',
                  size: 'sm',
                  iconMenus: 'ArrowUp',
                  tooltipText: `${t('button:previous')}`,
                  isDisabled: fileIndex === 0,
                  onClick: () => setFileIndex(fileIndex - 1)
                },
                {
                  configurations: 'ghost',
                  className: 'ml-2 mr-6',
                  type: 'secondary',
                  size: 'sm',
                  iconMenus: 'ArrowDown',
                  tooltipText: `${t('button:next')}`,
                  isDisabled: files.length - 1 === fileIndex,
                  onClick: () => setFileIndex(fileIndex + 1)
                },
                {
                  type: 'secondary',
                  size: 'sm',
                  label: `${t('button:cancel')}`,
                  onClick: () => handleOpenDiscardUnsavedChanges()
                },
                {
                  className: 'ml-3',
                  type: 'primary',
                  size: 'sm',
                  label: `${t('button:addCandidates')}`,
                  isDisabled: !!files.filter(
                    (item) => item.status === 'pending'
                  )?.length,
                  onClick: () => handleOK()
                }
              ]
            : []
        }}
        contentRef={drawerContainerRef}
        open={openDrawer}
        onClose={handleOpenDiscardUnsavedChanges}
        onEscapeKeyDown={handleOpenDiscardUnsavedChanges}>
        {renderedContent()}
      </Drawer>
    </UploadCVContext.Provider>
  )
}

export const useGetUploadDrawerRef = () => {
  const uploadContext = useContext(UploadCVContext)
  return uploadContext.drawerContainerRef?.current
}

const UploadCVContext = createContext<{
  drawerContainerRef?: React.RefObject<HTMLDivElement>
}>({})

export default withQueryClientProvider(UploadCVContainer)
