import { EventContentArg } from '@fullcalendar/core'
import { Url } from 'next/dist/shared/lib/router/router'
import Link from 'next/link'
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import { UseQueryExecute } from 'urql'
import configuration from '~/configuration'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { Avatar } from '~/core/ui/Avatar'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { getTimeZone } from '~/core/utilities/common'
import { defaultFormatDate, timeFormatDate } from '~/core/utilities/format-date'
import { pushStateBrowser } from '~/core/utilities/is-browser'
import QueryInterviewDetail from '~/lib/features/calendar/graphql/query-interviews-detail'
import { useMoreLinkPopover } from '~/lib/features/calendar/hooks/use-get-more-link-popover'
import { EditModalProps } from '~/lib/features/calendar/hooks/use-schedule-interview-calendar'
import { ActionInterviewManagement } from '~/lib/features/calendar/types'
import { FEEDBACK_OPTIONS } from '~/lib/features/calendar/utilities/enum.cva'
import { changeTimezone } from '~/lib/features/calendar/utilities/helper-schedule-interview'
import { checkShowFeedbackInterview } from '~/lib/features/candidates/utilities'
import useInterviewStore from '~/lib/features/interviews/store'
import usePermissionCandidate from '~/lib/features/permissions/hooks/use-permission-candidate'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import useBoundStore from '~/lib/store'
import OpenNewTabLink from '../OpenInNewTab'
import InterviewDeleteModal from './InterviewDeleteModal'

const CalendarPopupDetail: FC<{
  interviewId?: number
  isCalendarView?: boolean
  eventInfo?: EventContentArg
  closeEvent?: (() => void) | undefined
  refetchCalendar?: UseQueryExecute
  updateInterview: ActionInterviewManagement['interviewUpdateAction']['updateInterview']
  setEditModalInterview: Dispatch<SetStateAction<EditModalProps>>
}> = ({
  interviewId = -1,
  isCalendarView = true,
  eventInfo,
  closeEvent,
  refetchCalendar,
  updateInterview,
  setEditModalInterview
}) => {
  const { t } = useTranslation()
  const { user, currentRole } = useBoundStore()
  const { setOpenInterviewFeedbackDrawer, setInterviewDrawer } =
    useInterviewStore()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { moreLinkPopover } = useMoreLinkPopover()
  const {
    canAccessFullPermissionJob,
    actionInterview,
    actionJobIkitEvaluation,
    canAccessFullPermissionJobIkitEvaluation
  } = usePermissionJob()
  const { canAccessFullPermissionApplicant } = usePermissionCandidate({})

  const {
    isLoading,
    data: interview,
    trigger: refetchInterviewDetail
  } = useQueryGraphQL({
    query: QueryInterviewDetail,
    variables: {
      id: isCalendarView
        ? Number(eventInfo?.event._def.extendedProps?.id)
        : interviewId
    },
    shouldPause: false
  })

  const hiringTeamIds = useMemo(
    () =>
      !!interview?.interviewsShow
        ? [
            interview.interviewsShow.job?.owner?.id,
            ...(interview.interviewsShow.job?.jobRecruiters || []).map(
              (item) => item?.user?.id
            )
          ].map((id) => Number(id))
        : [],
    [interview]
  )

  const onCloseDeleteModal = useCallback(() => {
    setOpenDeleteModal(false)
  }, [])

  const renderContentDetail = () => {
    return (
      <>
        <div className="px-4 py-3">
          <div className="mb-0.5 flex items-center justify-between">
            <div className="flex flex-row items-center">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                className="hover:underline"
                href={interview?.interviewsShow?.previewLink as Url}>
                <TypographyText className="text-lg font-semibold text-gray-900">
                  {interview?.interviewsShow?.eventTypeDescription}
                </TypographyText>
              </Link>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                className="ml-2"
                href={interview?.interviewsShow?.previewLink as Url}>
                <IconWrapper size={16} name="ExternalLink" />
              </Link>
            </div>

            <div className="flex items-center space-x-2">
              {actionInterview.update && (
                <Tooltip content={`${t('tooltip:edit')}`}>
                  <IconButton
                    iconMenus="Edit3"
                    onClick={() => {
                      setEditModalInterview({
                        open: true,
                        interviewId: Number(interview?.interviewsShow.id),
                        scheduleCallback: () => {
                          refetchInterviewDetail()
                          refetchCalendar && refetchCalendar()
                        }
                      })
                    }}
                    size="xs"
                    type="secondary"
                  />
                </Tooltip>
              )}
              {!interview?.interviewsShow?.ikitFeedbacksSummary?.length &&
                actionInterview.delete && (
                  <Tooltip content={`${t('tooltip:delete')}`}>
                    <IconButton
                      iconMenus="Trash2"
                      onClick={() => setOpenDeleteModal(true)}
                      size="xs"
                      type="secondary-destructive"
                    />
                  </Tooltip>
                )}
              <IconButton
                iconMenus="X"
                onClick={closeEvent}
                size="xs"
                type="secondary"
              />
            </div>
          </div>
          <div>
            {interview?.interviewsShow?.fromDatetime && (
              <TypographyText className="mb-3 flex items-center text-xs text-gray-500">
                {defaultFormatDate(
                  changeTimezone({
                    date: interview?.interviewsShow?.fromDatetime,
                    timezone: user?.timezone
                  })
                )}
                <div className="mx-1.5 h-0.5 w-0.5 rounded bg-gray-500" />
                <span>
                  {' '}
                  {timeFormatDate(
                    changeTimezone({
                      date: interview?.interviewsShow?.fromDatetime,
                      timezone: user?.timezone
                    })
                  )}{' '}
                  -{' '}
                  {interview?.interviewsShow?.toDatetime &&
                    timeFormatDate(
                      changeTimezone({
                        date: interview?.interviewsShow?.toDatetime,
                        timezone: user?.timezone
                      })
                    )}{' '}
                  {interview?.interviewsShow?.toDatetime &&
                    `(GMT${getTimeZone(
                      interview?.interviewsShow?.toDatetime
                    )})`}
                </span>
              </TypographyText>
            )}

            {interview?.interviewsShow?.profile?.id && (
              <div className="mb-1 flex items-center py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px] flex-none items-center">
                  <IconWrapper
                    name="User"
                    size={14}
                    className="mr-1.5 text-gray-500"
                  />
                  <TypographyText className="text-xs text-gray-600">
                    {t('label:candidate')}
                  </TypographyText>
                </div>
                <div className="flex items-center">
                  <OpenNewTabLink
                    label={interview?.interviewsShow?.profile?.fullName}
                    avatar={
                      interview?.interviewsShow?.profile?.avatarVariants?.thumb
                        ?.url
                    }
                    avatarColor="#FFFFFF"
                    tooltip={String(interview?.interviewsShow?.profile?.email)}
                    link={
                      hiringTeamIds.includes(Number(user.id)) ||
                      canAccessFullPermissionApplicant
                        ? pathConfiguration.candidates.detail(
                            interview?.interviewsShow?.profile?.id,
                            interview?.interviewsShow?.applicant?.id
                          )
                        : undefined
                    }
                  />
                </div>
              </div>
            )}
            {interview?.interviewsShow?.job && (
              <div className="mb-1 flex items-center py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px] flex-none items-center">
                  <IconWrapper
                    name="Briefcase"
                    size={14}
                    className="mr-1.5 text-gray-500"
                  />
                  <TypographyText className="text-xs text-gray-600">
                    {t('label:job')}
                  </TypographyText>
                </div>
                <div>
                  <OpenNewTabLink
                    showAvatar={false}
                    label={interview?.interviewsShow?.job?.title}
                    link={
                      hiringTeamIds.includes(Number(user.id)) ||
                      canAccessFullPermissionJob
                        ? pathConfiguration?.jobs?.detail(
                            interview?.interviewsShow?.job?.id
                          )
                        : undefined
                    }
                  />
                </div>
              </div>
            )}
            {interview?.interviewsShow?.jobStage?.stageLabel && (
              <div className="mb-1 flex items-start py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px] items-center">
                  <IconWrapper
                    name="Database"
                    size={14}
                    className="mr-1.5 text-gray-500"
                  />
                  <TypographyText className="text-xs text-gray-600">
                    {t('label:stage')}
                  </TypographyText>
                </div>
                <div className="flex-1">
                  <TypographyText className="text-sm text-gray-900">
                    {interview?.interviewsShow?.jobStage?.stageLabel}
                  </TypographyText>
                </div>
              </div>
            )}
            {interview?.interviewsShow?.attendees &&
              interview?.interviewsShow?.attendees?.length > 0 && (
                <div className="mb-1 flex items-center py-1.5 last:mb-0">
                  <div className="mr-4 flex w-[88px] items-center">
                    <IconWrapper
                      name="Users"
                      size={14}
                      className="mr-1.5 text-gray-500"
                    />
                    <TypographyText className="text-xs text-gray-600">
                      {t('label:attendees')}
                    </TypographyText>
                  </div>
                  <div>
                    <AvatarGroup
                      size="xs"
                      source={interview?.interviewsShow?.attendees?.map(
                        (item, index) => ({
                          id: Number(item.id || index),
                          alt: item.fullName,
                          src: item.avatarVariants?.thumb?.url,
                          tooltip: (
                            <>
                              <div>{item.fullName}</div>
                              {item.email}
                            </>
                          ),
                          defaultColour: item.defaultColour
                        })
                      )}
                    />
                  </div>
                </div>
              )}
            {Object.values(interview?.interviewsShow?.location || {}).length >
              0 && (
              <div className="mb-1 flex items-start py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px] items-center">
                  <IconWrapper
                    name="MapPin"
                    size={14}
                    className="mr-1.5 text-gray-500"
                  />
                  <TypographyText className="text-xs text-gray-600">
                    {t('label:location')}
                  </TypographyText>
                </div>
                <div className="flex-1">
                  <TypographyText className="text-sm text-gray-900">
                    {Object.values(interview?.interviewsShow?.location || {})
                      .filter(
                        (locationValue) =>
                          !!locationValue &&
                          locationValue !==
                            interview?.interviewsShow?.location?.id
                      )
                      .join(', ')}
                  </TypographyText>
                </div>
              </div>
            )}
            {interview?.interviewsShow?.meetingUrl && (
              <div className="mb-1 flex items-center py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px] items-center">
                  <IconWrapper
                    name="Link"
                    size={14}
                    className="mr-1.5 text-gray-500"
                  />
                  <TypographyText className="text-xs text-gray-600">
                    {t('label:URL')}
                  </TypographyText>
                </div>
                <div className="flex-1">
                  <a
                    href={
                      interview?.interviewsShow?.meetingUrl?.match(
                        /^(https?:\/\/)/
                      )
                        ? interview?.interviewsShow?.meetingUrl
                        : `https://${interview?.interviewsShow?.meetingUrl}`
                    }>
                    <TypographyText
                      className={'text-sm text-gray-900 hover:underline'}>
                      {t('button:clickHere')}
                    </TypographyText>
                  </a>
                </div>
              </div>
            )}
            {interview?.interviewsShow?.organizer?.id && (
              <div className="mb-1 flex items-center py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px] items-center">
                  <IconWrapper
                    name="CalendarPlus"
                    size={14}
                    className="mr-1.5 text-gray-500"
                  />
                  <TypographyText className="text-xs text-gray-600">
                    {t('label:organizer')}
                  </TypographyText>
                </div>
                <div className="flex flex-1 items-center">
                  <div className="mr-1.5">
                    <Avatar
                      color={
                        interview?.interviewsShow?.organizer?.defaultColour
                      }
                      size="xs"
                      src={
                        interview?.interviewsShow?.organizer?.avatarVariants
                          ?.thumb?.url
                      }
                      alt={interview?.interviewsShow?.organizer?.fullName}
                    />
                  </div>
                  <Tooltip
                    content={interview?.interviewsShow?.organizer?.fullName}>
                    <TypographyText className="line-clamp-1 text-sm text-gray-900">
                      {interview?.interviewsShow?.organizer?.fullName}
                    </TypographyText>
                  </Tooltip>
                </div>
              </div>
            )}

            {interview?.interviewsShow?.ikitFeedbacksSummary?.length ? (
              <div className="mb-1 flex items-start py-1.5 last:mb-0">
                <div className="mr-4 flex w-[88px]">
                  <div className="min-w-[14px] pt-[2px]">
                    <IconWrapper
                      name="FileEdit"
                      size={14}
                      className="mr-1.5 text-gray-500"
                    />
                  </div>
                  <TypographyText className="text-xs text-gray-600">
                    {t('interview:feedback_modal:title')}
                  </TypographyText>
                </div>
                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="flex items-center space-x-1">
                      {interview?.interviewsShow?.ikitFeedbacksSummary.map(
                        (feedback, feedbackIndex) => {
                          const feedbackFilter = FEEDBACK_OPTIONS.map(
                            (item) => ({
                              ...item,
                              label: `${t(`candidates:feedback:${item.value}`)}`
                            })
                          ).filter((s) => s.value === feedback?.[0])

                          return (
                            <div
                              key={`ikit-feedback-sumary-${feedbackIndex}`}
                              className="flex space-x-1">
                              <Badge
                                variant="outline"
                                size="md"
                                radius="rounded"
                                iconSVG={feedbackFilter[0].iconMenus}
                                type="iconLeading">
                                {feedback?.[1]}
                              </Badge>
                            </div>
                          )
                        }
                      )}
                    </div>
                    {!!interview?.interviewsShow?.currentUserFeedback?.id ? (
                      <div className="ml-3">
                        <TextButton
                          label={t('button:viewFeedback')}
                          size="sm"
                          underline={false}
                          onClick={() => {
                            setInterviewDrawer({
                              id: String(interview?.interviewsShow?.id),
                              returnUrl: location.href,
                              openForm: false
                            })
                            setTimeout(() => {
                              pushStateBrowser({
                                state: {
                                  id: String(interview?.interviewsShow?.id)
                                },
                                unused: '',
                                url: configuration.path.interviews.detail(
                                  Number(interview?.interviewsShow?.id)
                                )
                              })
                              setOpenInterviewFeedbackDrawer(true)
                            }, 0)
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {checkShowFeedbackInterview({
          type: 'button',
          user,
          currentRole,
          item: interview?.interviewsShow,
          permission: canAccessFullPermissionJobIkitEvaluation
        }) && (
          <If condition={actionJobIkitEvaluation.create}>
            <div className="px-4 pb-3">
              <Button
                type="secondary"
                size="sm"
                iconMenus="FileEdit"
                className="w-full"
                onClick={() => {
                  setInterviewDrawer({
                    id: String(interview?.interviewsShow?.id),
                    returnUrl: location.href,
                    openForm: true
                  })
                  setTimeout(() => {
                    pushStateBrowser({
                      state: { id: String(interview?.interviewsShow?.id) },
                      unused: '',
                      url: configuration.path.interviews.detail(
                        Number(interview?.interviewsShow?.id)
                      )
                    })
                    setOpenInterviewFeedbackDrawer(true)
                  }, 0)
                }}
                label={`${t('button:submitFeedback')}`}
              />
            </div>
          </If>
        )}

        <InterviewDeleteModal
          open={openDeleteModal}
          onClose={onCloseDeleteModal}
          callbackDeleteInterview={refetchCalendar}
          interviewId={Number(interview?.interviewsShow?.id)}
          eventType={interview?.interviewsShow?.eventTypeDescription}
        />
      </>
    )
  }

  if (isCalendarView) {
    return !!interview ? (
      <div
        {...(!!moreLinkPopover
          ? { onMouseDown: (e) => e.stopPropagation() }
          : {})}>
        {renderContentDetail()}
      </div>
    ) : (
      <div className="px-4 py-3">
        <Skeleton className="mb-3 h-4 w-full rounded" />
        <Skeleton className="mb-3 h-4 w-full rounded" />
        <Skeleton className="h-4 w-full rounded" />
      </div>
    )
  }
  return !!interview ? (
    <div>{renderContentDetail()}</div>
  ) : (
    <div className="px-4 py-3">
      <Skeleton className="mb-3 h-4 w-full rounded" />
      <Skeleton className="mb-3 h-4 w-full rounded" />
      <Skeleton className="h-4 w-full rounded" />
    </div>
  )
}

export default CalendarPopupDetail
