'use client'

import * as DropdownMenuWrapper from '@radix-ui/react-dropdown-menu'
import { cva } from 'class-variance-authority'
import { ReactNode, forwardRef, useState } from 'react'
import { Avatar, AvatarProps } from '~/core/ui/Avatar'
import { Dot, IDotColorProps } from '~/core/ui/Dot'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'

const dropdownMenuContentVariants = cva(
  'overflow-hidden rounded bg-white pt-px pb-px shadow-ats dark:bg-gray-800 dark:shadow-dark-ats z-10',
  {
    variants: {
      size: {
        large: 'w-80',
        medium: 'w-[248px]',
        small: 'w-[188px]'
      }
    },
    defaultVariants: {
      size: 'small'
    }
  }
)

const dropdownMenuSubContentVariants = cva(
  'rounded bg-white p-1 shadow-ats dark:bg-gray-800 dark:shadow-dark-ats',
  {
    variants: {
      size: {
        large: 'w-80',
        medium: 'w-[248px]',
        small: 'w-[188px]'
      }
    },
    defaultVariants: {
      size: 'small'
    }
  }
)

type DropdownMenuMenuItemOption = {
  label?: string
  labelDescription?: string
  icon?: LucideIconName
  dot?: IDotColorProps
  dotDescription?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isChecked?: boolean
  onCheckedChange?: (checked: boolean) => void
  color?: 'red' | 'gray'
  subordinates?: Array<DropdownMenuMenuItem>
  isSwitchExtraRendered?: boolean
  disabled?: boolean
  description?: string
}
type DropdownMenuMenuItemString = 'separate-line'
export type DropdownMenuMenuItem =
  | DropdownMenuMenuItemOption
  | DropdownMenuMenuItemString

interface DropdownMenuProps {
  trigger?: ReactNode | ((openDropdown: boolean) => ReactNode)
  side?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'start' | 'center' | 'end'
  sideOffset?: number
  className?: string
  menuClassName?: string
  menu?: Array<DropdownMenuMenuItem | undefined>
  size?: 'small' | 'medium' | 'large'
  isWithHeader?: boolean
  isDisabledHeader?: boolean
  avatarProps?: {
    name?: string
    email?: string
    bottomContent?: ReactNode
    nameExtraContent?: ReactNode
    onClickEmail?: () => void
  } & AvatarProps
  showCheckedIcon?: boolean
  isDisabled?: boolean
  extraRendered?: {
    title?: string
    content: ReactNode
  }
}

const DropdownMenu = forwardRef<
  React.ElementRef<typeof DropdownMenuWrapper.Content>,
  DropdownMenuProps
>((props, ref) => {
  const {
    trigger,
    side = 'top',
    align = 'start',
    sideOffset = 8,
    menu = [],
    size = 'small',
    isWithHeader = false,
    isDisabledHeader = false,
    avatarProps,
    menuClassName = '',
    showCheckedIcon = false,
    isDisabled = false,
    extraRendered
  } = props
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [openExtraRendered, setOpenExtraRendered] = useState(false)

  return (
    <DropdownMenuWrapper.Root
      open={openDropdown}
      onOpenChange={(open) => {
        setOpenDropdown(open)
        setOpenExtraRendered(false)
      }}>
      {trigger ? (
        <DropdownMenuWrapper.Trigger disabled={isDisabled} asChild>
          <div>
            {typeof trigger === 'function' ? trigger(openDropdown) : trigger}
          </div>
        </DropdownMenuWrapper.Trigger>
      ) : null}

      <DropdownMenuWrapper.Portal>
        <DropdownMenuWrapper.Content
          ref={ref}
          align={align}
          side={side}
          sideOffset={sideOffset}
          onEscapeKeyDown={() => {
            setOpenDropdown(false)
            setOpenExtraRendered(false)
          }}
          className={cn(dropdownMenuContentVariants({ size }), menuClassName)}>
          {extraRendered?.content && openExtraRendered ? (
            <>
              <div className="flex items-center p-3">
                <button onClick={() => setOpenExtraRendered(false)}>
                  <IconWrapper
                    name="ChevronLeft"
                    size={20}
                    className="mr-4 hover:text-primary-600"
                  />
                </button>
                <p className="text-base font-medium text-gray-900">
                  {extraRendered?.title}
                </p>
              </div>
              <div className="h-px bg-gray-300 dark:bg-gray-600" />
              {extraRendered.content}
            </>
          ) : (
            <>
              {isWithHeader && (
                <div
                  className={
                    isDisabledHeader ? `pointer-events-none opacity-50` : ''
                  }>
                  <DropdownWithAvatarHeader {...avatarProps} />
                  <DropdownMenuWrapper.Separator className="h-px bg-gray-300 dark:bg-gray-600" />
                </div>
              )}
              <div className="m-1">
                {menu?.map((item, index) => {
                  const menuOption =
                    typeof item === 'object'
                      ? (item as DropdownMenuMenuItemOption)
                      : undefined
                  const menuString =
                    typeof item === 'string' ? (item as string) : undefined
                  const disabled = menuOption && menuOption?.disabled

                  const rendered = () => {
                    return (
                      <>
                        <div className="flex items-center">
                          {menuOption?.icon ? (
                            <IconWrapper
                              name={menuOption?.icon}
                              size={16}
                              className={`mr-3 ${
                                menuOption.color === 'red'
                                  ? 'text-red-500'
                                  : 'text-gray-500 dark:text-gray-400'
                              }`}
                            />
                          ) : null}
                          {menuOption?.labelDescription ? (
                            <Tooltip
                              content={menuOption?.labelDescription}
                              classNameAsChild={`text-sm font-medium ${
                                menuOption?.color === 'red'
                                  ? 'text-red-500'
                                  : 'text-gray-600 dark:text-gray-300'
                              }`}>
                              {menuOption?.label}
                            </Tooltip>
                          ) : (
                            <span
                              className={`text-sm font-medium ${
                                menuOption?.color === 'red'
                                  ? 'text-red-500'
                                  : 'text-gray-600 dark:text-gray-300'
                              }`}>
                              {menuOption?.label}
                            </span>
                          )}
                        </div>
                        <div>
                          <IconWrapper
                            name="ChevronRight"
                            size={14}
                            className="text-gray-500 dark:text-gray-400"
                          />
                        </div>
                      </>
                    )
                  }

                  return (
                    <div
                      className={
                        disabled ? `pointer-events-none opacity-50` : ''
                      }
                      key={index}>
                      {menuOption?.subordinates ? (
                        <DropdownMenuWrapper.Sub key={index}>
                          <DropdownMenuWrapper.SubTrigger className="flex items-center justify-between rounded px-3 py-[9px] outline-none hover:bg-gray-50 dark:hover:bg-gray-700">
                            {rendered()}
                          </DropdownMenuWrapper.SubTrigger>
                          <DropdownMenuWrapper.Portal>
                            <DropdownMenuWrapper.SubContent
                              sideOffset={8}
                              alignOffset={0}
                              className={cn(
                                dropdownMenuSubContentVariants({ size })
                              )}>
                              <>
                                {menuOption?.subordinates?.map((sub, index) => {
                                  const subMenuOption =
                                    typeof sub === 'object'
                                      ? (sub as DropdownMenuMenuItemOption)
                                      : undefined
                                  return (
                                    subMenuOption && (
                                      <div
                                        onClick={subMenuOption.onClick}
                                        className="relative flex flex-1 cursor-pointer items-center rounded hover:bg-gray-50 dark:hover:bg-gray-700">
                                        <DropdownMenuWrapperItem
                                          key={index}
                                          {...subMenuOption}
                                        />
                                      </div>
                                    )
                                  )
                                })}
                              </>
                            </DropdownMenuWrapper.SubContent>
                          </DropdownMenuWrapper.Portal>
                        </DropdownMenuWrapper.Sub>
                      ) : (
                        menuOption && (
                          <div className="relative flex cursor-pointer items-center rounded pr-3 hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div
                              className="flex-1"
                              onClick={(e) => {
                                if (menuOption.isSwitchExtraRendered) {
                                  setOpenExtraRendered(true)
                                } else {
                                  menuOption?.onClick && menuOption.onClick(e)
                                }
                              }}>
                              <DropdownMenuWrapperItem {...menuOption} />
                            </div>
                            {menuOption?.onCheckedChange ? (
                              <Toggle
                                size="sm"
                                isChecked={menuOption.isChecked}
                                onCheckedChange={menuOption.onCheckedChange}
                              />
                            ) : null}

                            {showCheckedIcon && menuOption.isChecked && (
                              <IconWrapper name="Check" size={20} />
                            )}
                          </div>
                        )
                      )}

                      {menuString === 'separate-line' && (
                        <DropdownMenuWrapper.Separator className="-mx-1 my-1 h-px bg-gray-100 dark:bg-gray-600" />
                      )}
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </DropdownMenuWrapper.Content>
      </DropdownMenuWrapper.Portal>
    </DropdownMenuWrapper.Root>
  )
})

const DropdownMenuWrapperItem = (option: DropdownMenuMenuItemOption) => {
  if (option.isSwitchExtraRendered) {
    return (
      <div className="flex-1 px-3 py-[9px] outline-none">
        <button type="button" className="flex w-full items-center">
          {option?.icon ? (
            <IconWrapper
              name={option?.icon}
              size={16}
              className={`mr-3 ${
                option.color === 'red'
                  ? 'text-red-500'
                  : 'text-gray-500 dark:text-gray-400'
              }`}
            />
          ) : null}

          {option?.labelDescription ? (
            <Tooltip
              classNameAsChild={`line-clamp-1 flex-1 text-left text-sm font-medium ${
                option.color === 'red'
                  ? 'text-red-500 dark:text-red-500'
                  : 'text-gray-600 dark:text-gray-300'
              }`}
              classNameConfig={{
                content: 'z-[100]'
              }}
              content={option?.labelDescription}>
              {option?.label}
            </Tooltip>
          ) : (
            <span
              className={`line-clamp-1 flex-1 text-left text-sm font-medium ${
                option.color === 'red'
                  ? 'text-red-500 dark:text-red-500'
                  : 'text-gray-600 dark:text-gray-300'
              }`}>
              {option?.label}
            </span>
          )}

          <IconWrapper
            name="ChevronRight"
            size={14}
            className="text-gray-500 dark:text-gray-400"
          />
        </button>
      </div>
    )
  }

  return (
    <DropdownMenuWrapper.Item className="flex-1 px-3 py-[9px] outline-none">
      <button type="button" className="block">
        <div className="flex w-full items-center">
          {option?.icon ? (
            <IconWrapper
              name={option?.icon}
              size={16}
              className={`mr-3 ${
                option.color === 'red'
                  ? 'text-red-500'
                  : 'text-gray-500 dark:text-gray-400'
              }`}
            />
          ) : null}
          {option.dot ? (
            <>
              {option?.dotDescription ? (
                <Tooltip
                  classNameAsChild="mr-2 flex items-center"
                  classNameConfig={{
                    content: 'z-[100]'
                  }}
                  content={option?.dotDescription}>
                  <Dot size="xl" color={option.dot} />
                </Tooltip>
              ) : (
                <div className="mr-2 flex items-center">
                  <Dot size="xl" color={option.dot} />
                </div>
              )}
            </>
          ) : null}

          {option?.labelDescription ? (
            <Tooltip
              classNameAsChild={`line-clamp-1 flex-1 text-left text-sm font-medium ${
                option.color === 'red'
                  ? 'text-red-500 dark:text-red-500'
                  : 'text-gray-600 dark:text-gray-300'
              }`}
              classNameConfig={{
                content: 'z-[100]'
              }}
              content={option?.labelDescription}>
              {option?.label}
            </Tooltip>
          ) : (
            <span
              className={`line-clamp-1 flex-1 text-left text-sm font-medium ${
                option.color === 'red'
                  ? 'text-red-500 dark:text-red-500'
                  : 'text-gray-600 dark:text-gray-300'
              }`}>
              {option?.label}
            </span>
          )}
        </div>
        {option?.description ? (
          <div className="flex text-left text-xs text-gray-500">
            {option?.description}
          </div>
        ) : null}
      </button>
    </DropdownMenuWrapper.Item>
  )
}

const DropdownWithAvatarHeader = (
  avatarProps: Exclude<DropdownMenuProps['avatarProps'], null | undefined>
) => {
  return (
    <div
      className="m-1 rounded px-3 py-2 hover:bg-gray-50 dark:hover:bg-gray-700"
      {...(!!avatarProps?.onClick ? { onClick: avatarProps?.onClick } : {})}>
      <button type="button" className="flex w-full">
        <Avatar {...avatarProps} size="lg" />
        <div className="ml-3 overflow-hidden text-left">
          <div className="flex items-center">
            <p className="line-clamp-1 break-all text-sm font-medium text-gray-900 dark:text-gray-200">
              {avatarProps.name}
            </p>
            {avatarProps.nameExtraContent}
          </div>
          <p
            className={`line-clamp-1 break-all text-sm text-gray-500 dark:text-gray-400`}>
            {avatarProps.email}
          </p>
          {avatarProps.bottomContent}
        </div>
      </button>
    </div>
  )
}

DropdownMenu.displayName = 'DropdownMenu'

export { DropdownMenu, DropdownMenuWrapperItem, DropdownWithAvatarHeader }
