import { Dispatch, SetStateAction } from 'react'
import { StateCreator } from 'zustand'
import { IUploadDrawerJob } from '../types'

export interface UploadSlice {
  openUploadDrawer: boolean
  setOpenUploadDrawer: (open: boolean) => void
  uploadDrawerJobId?: IUploadDrawerJob
  setUploadDrawerJobId: (
    uploadDrawerJobId: Array<{
      defaultValue?: boolean
      profileId?: string
      jobId?: string
      jobTitle?: string
    }>
  ) => void
  isShowFullDrawer?: boolean
  setShowFullDrawer: (showFull: boolean) => void
}

export const createUploadSlice: StateCreator<
  UploadSlice,
  [],
  [],
  UploadSlice
> = (set: Function) => ({
  openUploadDrawer: false,
  setOpenUploadDrawer: (open: boolean) => set({ openUploadDrawer: open }),
  uploadDrawerJobId: undefined,
  setUploadDrawerJobId: (
    uploadDrawerJobId: Array<{
      defaultValue?: boolean
      profileId?: string
      jobId?: string
      jobTitle?: string
    }>
  ) => set({ uploadDrawerJobId }),
  isShowFullDrawer: false,
  setShowFullDrawer: (showFull: boolean) => set({ isShowFullDrawer: showFull })
})
