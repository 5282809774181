import { gql } from 'urql'

const QueryCandidateDetail = gql`
  query ($id: Int!, $talentPoolId: Int) {
    profilesShow(id: $id, talentPoolId: $talentPoolId) {
      id
      fullName
      email
      headline
      phoneNumber
      address
      avatarVariants
      coverLetter
      countryStateId
      links
      sourced
      sourcedDescription
      sourcedName
      sourcedNameDescription
      createdAt
      profileCvs {
        id
        attachments {
          id
          file
          blobs
        }
      }
      jobs {
        id
        title
      }
      applicants {
        id
        coverLetter
        job {
          status
          id
          title
          slug
          owner {
            id
            fullName
            email
          }
        }
      }
      applicableJobs {
        id
        title
        jobLocations {
          state
          country
        }
        owner {
          id
          fullName
          email
        }
        department {
          name
        }
      }
      user {
        id
        fullName
        avatarVariants
        defaultColour
      }
      owner {
        id
        fullName
        avatarVariants
        defaultColour
      }
      totalYearsOfExp
      permittedFields
      tags {
        value
        name
        id
      }
      warning
      defaultAccessibleApplicantId
      customFields
      employeeId
    }
  }
`

export default QueryCandidateDetail
