import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, FieldPath, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IAttachmentsFile } from '~/core/@types/global'
import { AGENCY_TENANT } from '~/core/constants/enum'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { Avatar } from '~/core/ui/Avatar'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { ExclamationFill } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { InlineEditingTextarea } from '~/core/ui/InlineEditing'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { UploadImage } from '~/core/ui/UploadImage'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { isBrowser } from '~/core/utilities/is-browser'
import MutationAgencyProfileUpdate from '~/lib/features/candidates/graphql/mutation-agency-update-profile'
import MutationProfileUpdate from '~/lib/features/candidates/graphql/mutation-update-profile'
import QueryAgencyCandidateDetail from '~/lib/features/candidates/graphql/query-agency-candidate-detail'
import QueryCandidateDetail from '~/lib/features/candidates/graphql/query-candidate-detail'
import { schemaUpdateProfile } from '~/lib/features/candidates/schema/validation-update-profile'
import useCandidateStore from '~/lib/features/candidates/store'
import {
  CandidateProfileInputType,
  ICandidateProfile
} from '~/lib/features/candidates/types'
import { DEFAULT_CURRENCY } from '~/lib/features/candidates/utilities/enum'
import { CustomFieldFormType } from '~/lib/features/settings/profile-fields/types/custom-field'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import AttachmentView from '../AttachmentView'
import DisableControlByPermission from '../Candidates/Profile/components/DisableControlByPermission'
import ContactDetailsView from '../Candidates/Profile/components/Overview/ContactDetailsView'
import ProfileInformationView from '../Candidates/Profile/components/Overview/ProfileInformationView'
import SkeletonContainer from '../Skeleton'

const UploadCVProfileView = ({ item }: { item: IAttachmentsFile }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const setToast = useBoundStore((state) => state.setToast)
  const user = useBoundStore((state) => state.user)
  const { clientGraphQL } = useContextGraphQL()
  const [data, setData] = useState<ICandidateProfile>({})
  const { uploadDrawerJobId, setUploadDrawerJobId } = useCandidateStore()
  const divRef = useRef<HTMLDivElement>(null)
  const permittedFields = data?.permittedFields
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })
  const { trigger: triggerUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useSubmitCommon(
      isCompanyKind ? MutationAgencyProfileUpdate : MutationProfileUpdate
    )

  const fetchProfile = useCallback(() => {
    return clientGraphQL
      .query(
        isCompanyKind ? QueryAgencyCandidateDetail : QueryCandidateDetail,
        {
          id: Number(item?.profileId)
        }
      )
      .toPromise()
      .then(
        (result: {
          error: { graphQLErrors: Array<object> }
          data: {
            profilesShow: {
              id: number
              createdAt: string
              profile: ICandidateProfile
            }
          }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              setToast,
              router
            })
          }

          const { profilesShow } = result.data
          return setData({
            ...profilesShow,
            id: profilesShow.id,
            createdAt: profilesShow.createdAt
          })
        }
      )
  }, [clientGraphQL, item?.profileId, router, setToast, isCompanyKind])

  useEffect(() => {
    if (item?.profileId) {
      handleFetchProfile()
    }
  }, [item?.profileId])

  const handleFetchProfile = async () => {
    setData({})
    setTimeout(() => {
      fetchProfile()
    }, 0)
  }

  const onUpdateProfile = useCallback(
    async (param: CandidateProfileInputType & { paramType: string }) => {
      if (isLoadingUpdateProfile) {
        return false
      }

      const fileObj = (param.avatarObject || '') as BlobPart
      return triggerUpdateProfile({
        ...param,
        id: Number(item?.profileId),
        ...(param.paramType === 'ownerId'
          ? { ownerId: param.ownerId || null }
          : {}),
        ...(param.avatarObject && typeof param.avatarObject === 'object'
          ? {
              avatar: new File([new Blob([fileObj])], 'avatar.jpeg')
            }
          : {}),
        ...(param.resumeFile && typeof param.resumeFile === 'object'
          ? {
              resumeFile: param.resumeFile[0]
            }
          : {}),
        paramType: undefined
      }).then((result) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
        } else {
          const { profilesUpdate } = result.data
          if (profilesUpdate.profile) {
            setData({
              ...profilesUpdate.profile,
              [param.paramType]: profilesUpdate.profile[param.paramType]
            })
          }
        }
      })
    },
    [item?.id, isLoadingUpdateProfile, setToast, triggerUpdateProfile]
  )

  const touchingFieldRef =
    useRef<FieldPath<CandidateProfileInputType & CustomFieldFormType>>()
  const onSubmit = (params: CandidateProfileInputType): Promise<void> => {
    const submittingField = touchingFieldRef.current as keyof typeof params
    if (submittingField) {
      return onUpdateProfile({
        [submittingField]:
          {
            phoneNumber: params[submittingField] ? params[submittingField] : [],
            email: params[submittingField] ? params[submittingField] : [],
            links: {
              links: params[submittingField]
                ? [params[submittingField].value]
                : [],
              _destroy: params[submittingField]?._destroy
            },
            ownerId: params?.ownerId?.value && parseInt(params?.ownerId?.value)
          }[submittingField as string] || params[submittingField],
        currentSalaryCurrency:
          submittingField === 'currentSalary'
            ? params.currentSalaryCurrency || user?.currentTenant?.currency
            : undefined,
        expectedSalaryCurrency:
          submittingField === 'expectedSalary'
            ? params.expectedSalaryCurrency || user?.currentTenant?.currency
            : undefined,
        sourcedName:
          submittingField === 'sourced'
            ? ''
            : submittingField === 'sourcedName'
            ? params.sourcedName
            : undefined,
        paramType: submittingField
      }).then(() => undefined)
    }
    return Promise.resolve()
  }

  const submitPartialField = (
    fieldName: FieldPath<CandidateProfileInputType & CustomFieldFormType>,
    validate: UseFormTrigger<CandidateProfileInputType & CustomFieldFormType>,
    submit?: () => Promise<void>
  ) => {
    if (touchingFieldRef) {
      touchingFieldRef.current = fieldName
    }

    return validate(fieldName).then((test) => {
      if (test) {
        return submit ? submit().then(() => test) : Promise.resolve(false)
      }
      return Promise.resolve(false)
    })
  }

  const formatJob = () => {
    let jobs = []

    const findDefaultValue = uploadDrawerJobId?.find(
      (item) => !!item.defaultValue
    )

    if (findDefaultValue?.jobId) {
      jobs.push({
        value: findDefaultValue.jobId?.toString(),
        supportingObj: { name: findDefaultValue.jobTitle }
      })
    } else {
      jobs =
        uploadDrawerJobId &&
        uploadDrawerJobId?.length > 0 &&
        !!uploadDrawerJobId.find(
          (fi) => Number(fi.profileId) === Number(data.id)
        )
          ? uploadDrawerJobId
              .filter((fi) => Number(fi.profileId) === Number(data.id))
              .map((item) => ({
                value: item.jobId?.toString(),
                supportingObj: { name: item.jobTitle }
              }))
          : []
    }

    return jobs
  }

  return (
    <SkeletonContainer
      showMoreLabel={`${t('common:infinity:showMore')}`}
      useLoading={false}
      isFirstLoading={item?.id === undefined || Object.keys(data).length === 0}
      classNameFirstLoading="flex-1 border-l border-solid border-gray-100 p-6"
      renderCustomSkeleton={
        <>
          <div className="mr-2 flex w-full items-center">
            <Skeleton className="h-[48px] w-[48px] rounded-full" />

            <div className="ml-4 w-[138px]">
              <Skeleton className="h-4 w-full rounded-none" />
            </div>
          </div>

          <div className="flex flex-col space-y-3 pt-[26px]">
            <Skeleton className="h-3 w-full rounded-none" />
            <Skeleton className="h-3 w-full rounded-none" />
            <Skeleton className="h-3 w-full rounded-none" />
          </div>
        </>
      }>
      <div className="flex h-[calc(100vh_-_122px)] flex-1 border-l border-solid border-gray-100">
        <ScrollArea className="h-[calc(100vh_-_122px)] flex-1">
          <div className="w-full space-y-4 p-6">
            <div className="flex items-center">
              <DisableControlByPermission
                applicantId={item?.id}
                text={
                  <Avatar
                    shape="circular"
                    size="xl"
                    src={data.avatarVariants?.thumb?.url}
                    alt={data.fullName}
                  />
                }>
                <UploadImage
                  configText={{
                    upload: `${t('button:upload')}`,
                    cancel: `${t('button:cancel')}`,
                    cropImage: `${t('button:cropImage')}`,
                    supportedFormat: `${t('form:supportedFormat', {
                      size: '10MB'
                    })}`
                  }}
                  type="avatar-only"
                  shape="circular"
                  size="xl"
                  avatarConfig={{
                    color: '#FFFFFF',
                    alt: data.fullName
                  }}
                  value={data.avatarVariants?.thumb?.url}
                  onChange={(newValue) => {
                    onUpdateProfile({
                      avatarObject: newValue,
                      paramType: 'avatar'
                    })
                  }}
                  dialogCropperTitle={`${t('label:adjustProfilePicture')}`}
                />
              </DisableControlByPermission>
              <div ref={divRef} className="w-full">
                <DynamicImportForm
                  mode="onSubmit"
                  id="profile-form"
                  className="w-full"
                  defaultValue={{
                    ...data,
                    customFields: {}
                  }}
                  schema={schemaUpdateProfile(t)}
                  onSubmit={onSubmit}>
                  {({ formState, control, trigger, getValues }) => {
                    const forceSubmit = () => onSubmit(getValues())
                    return (
                      <div className="ml-2 flex w-full flex-col">
                        <div className="flex w-full items-center justify-between">
                          <div className="w-full">
                            <Controller
                              control={control}
                              name="fullName"
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <FormControlItem>
                                    <DisableControlByPermission
                                      applicantId={item?.id}
                                      text={
                                        <div className="flex items-center px-2 py-1.5 text-xl font-semibold text-gray-900">
                                          {value}
                                        </div>
                                      }>
                                      <InlineEditingTextarea
                                        onChange={(newValue) => {
                                          onChange(newValue)
                                          return submitPartialField(
                                            'fullName',
                                            trigger,
                                            forceSubmit
                                          )
                                        }}
                                        onCancelUpdate={onChange}
                                        placeholder={`${t(
                                          'label:clickToEnterValue'
                                        )}`}
                                        className="-m-px h-full px-2 py-1.5 text-xl"
                                        size="md"
                                        value={value}
                                        tooltipActionCancel={{
                                          title: `${t('button:cancel')}`
                                        }}
                                        tooltipActionSave={{
                                          title: `${t('button:saveEnter')}`
                                        }}
                                        tooltipError={{
                                          position: 'right',
                                          align: 'start'
                                        }}
                                        destructiveText={
                                          formState?.errors?.fullName
                                            ?.message as string
                                        }>
                                        <div className="flex items-center px-2 py-1.5 text-xl font-semibold text-gray-900">
                                          {data.fullName}
                                        </div>
                                      </InlineEditingTextarea>
                                    </DisableControlByPermission>
                                  </FormControlItem>
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </DynamicImportForm>
              </div>
            </div>
            {data?.warning?.length ? (
              <div className="flex items-center space-x-2 rounded bg-gray-50 px-3 py-2">
                <div className="w-4">
                  <ExclamationFill className="!fill-orange-500" />
                </div>
                <div className="space-y-1">
                  {data.warning.map((item, index) => (
                    <TypographyText
                      key={index}
                      className="flex-1 break-words text-sm text-gray-600">
                      {item.full}
                    </TypographyText>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="space-y-1">
              <DynamicImportForm<CandidateProfileInputType>
                mode="onSubmit"
                id="profile-candidate-form"
                className="w-full"
                defaultValue={{
                  ...data,
                  customFields: {},
                  skills: permittedFields?.skills?.value,
                  summary: permittedFields?.summary?.value,
                  jobs: formatJob()
                }}
                schema={schemaUpdateProfile(t)}
                onSubmit={onSubmit}>
                {({ formState, control, getValues, trigger, setValue }) => {
                  const forceSubmit = () => onSubmit(getValues())
                  return (
                    <>
                      <ContactDetailsView
                        trigger={trigger}
                        submit={forceSubmit}
                        data={data}
                        control={control}
                        submitPartialField={submitPartialField}
                        formState={formState}
                        configHide={{
                          location: true
                        }}
                        isDrawer
                        uploadDrawerJobId={uploadDrawerJobId}
                        setUploadDrawerJobId={setUploadDrawerJobId}
                      />

                      <ProfileInformationView
                        trigger={trigger}
                        submit={forceSubmit}
                        data={data}
                        control={control}
                        onUpdateProfile={onUpdateProfile}
                        submitPartialField={submitPartialField}
                        formState={formState}
                        setValue={setValue}
                        configHide={{
                          birthday: true,
                          totalYearsOfExp: true,
                          currentSalary: true,
                          expectedSalary: true,
                          language: true,
                          nationality: true,
                          ownerId: true,
                          sourcedName: true,
                          willingToRelocate: true,
                          noticeToPeriodDays: true,
                          talentPools: true,
                          customFields: true
                        }}
                        inlineEditingOverlayEdited={{
                          skills: true
                        }}
                      />
                    </>
                  )
                }}
              </DynamicImportForm>
            </div>
          </div>
        </ScrollArea>

        <div className="flex-1 overflow-y-auto">
          {data.profileCvs && data.profileCvs.length > 0 ? (
            (data.profileCvs || []).map((group, i) => (
              <div key={i} className="min-h-full">
                {isBrowser() ? (
                  <AttachmentView
                    item={
                      group.attachments?.length
                        ? group.attachments[0]
                        : undefined
                    }
                  />
                ) : null}
              </div>
            ))
          ) : (
            <div className="flex h-full items-center justify-center text-sm text-gray-500">
              {t('candidates:tabs:candidateOverview:notAvailable')}
            </div>
          )}
        </div>
      </div>
    </SkeletonContainer>
  )
}

export default UploadCVProfileView
